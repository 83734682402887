import { useApplicationContext } from '@/context/ApplicationContext';
import { useGroups } from '@/modules/groups';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import CustomSearchableSelect, { CustomSearchableSelectOption } from './CustomSearchableSelect';

type GroupSelectInputProps = {
  defaultGroupValues: number[];
  onChange: (values: number[]) => void;
  isMyGroupDisabled?: boolean;
};

const GroupSelectInput = (props: GroupSelectInputProps) => {
  const { defaultGroupValues, onChange, isMyGroupDisabled = false } = props;
  const { selectGroups } = useGroups();
  const { groupsByUser } = useApplicationContext();
  const { t } = useTranslation();
  const [groupValues, setGroupValues] = useState<CustomSearchableSelectOption[]>([]);
  const isShowAssignToMyGroup =
    !isMyGroupDisabled && groupsByUser.length > 0 && groupValues.length === 0;

  const handleInput = useCallback(
    (values: CustomSearchableSelectOption[]) => {
      setGroupValues(values);
      onChange(values.map((groupValue) => Number(groupValue.value)));
    },
    [onChange]
  );

  const onAssignToMyGroup = () => {
    handleInput(
      groupsByUser.map((group) => ({
        value: group.id.toString(),
        label: group.name,
      }))
    );
  };

  useEffect(() => {
    if (defaultGroupValues.length > 0 && groupValues.length === 0) {
      setGroupValues(
        selectGroups
          .filter(({ value }) => defaultGroupValues.includes(Number(value)))
          .map(({ value, label }) => ({ value: value, label }))
      );
      return;
    }
  }, [defaultGroupValues, groupValues, selectGroups]);
  return (
    <>
      <CustomSearchableSelect
        label={t('group')}
        placeholder={t('warning.please-select')}
        value={groupValues}
        onChange={handleInput}
        options={selectGroups}
        isClearable
        isMulti
      />
      {isShowAssignToMyGroup && (
        <Button size='sm' variant='link' colorScheme='primary' p='1' onClick={onAssignToMyGroup}>
          {t('assign-to-my-groups')}
        </Button>
      )}
    </>
  );
};

export default GroupSelectInput;
